<template>
  <div>
    <v-row no-gutters v-if="activitesLoading">
      <v-col v-for="n in 5" :key="n" cols="12">
        <v-skeleton-loader class="mx-auto" type="list-item-avatar"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-list flat class="tile--list" v-else-if="listOfQuickLinks.length > 0 && !activitesLoading" dense>
      <v-list-item-group active-class="primary--text">
        <template v-for="(widget, index) in listOfQuickLinks">
          <v-hover v-slot:default="{ hover }" :key="`${index}_widget`">
            <v-list-item class="mb-0 pb-0">
              <v-avatar :color="widget.color" size="26" class="mr-2">
                <span class="white--text subtitle-1">{{ widget.name.charAt(0) }}</span>
              </v-avatar>
              <v-list-item-content class="pa-0">
                <v-list-item-subtitle class="text--primary" v-text="widget.name"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="my-0">
                <template v-if="hover">
                  <v-row>
                    <v-col cols="6" class="pa-0 px-1">
                      <v-btn x-small @click="$router.push(widget.link)" color="purple" elevation="10" fab dark>
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn></v-col>
                    </v-row>
                </template>
                <template v-else>
                  <v-row>
                    <v-col cols="6" class="pa-0 px-1">
                      <v-btn x-small icon color="grey" fab dark>
                        <v-icon class="mdi-18px">mdi-checkbox-blank-circle</v-icon>
                      </v-btn>
                    </v-col>
                    </v-row>
                </template>
              </v-list-item-action>
            </v-list-item>
          </v-hover>
          <v-divider v-if="index + 1 < listOfQuickLinks.length" :key="`${index}_divider`"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
    <v-alert class="mx-3 mt-2" color="info" dark icon="mdi-cloud-alert" border="left" prominent outlined v-else>
      {{ $t('noResults') }}
    </v-alert>
  </div>
</template>
<script>
export default {
  props: ['listOfQuickLinks', 'activitesLoading']
}
</script>
<style scoped>
.v-list-item--dense, .v-list--dense .v-list-item {
  min-height: 30px !important;
}
</style>
